import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import { BasicContainer } from 'components/content-blocks/basic-container';
import CustomerFeatureBenefit from 'components/content-blocks/customer-feature-benefit';
import QuoteBox from 'components/content-blocks/customer-quote-box';
import CustomerStories from 'components/content-blocks/customer-stories';
import FeaturesGrid from 'components/content-blocks/features-grid';
import { FeaturesList } from 'components/content-blocks/features-list';
import Highlight from 'components/content-blocks/highlight';
import Manager from 'components/content-blocks/manager';
import MediaObject from 'components/content-blocks/media-object';
import StructuredData from 'components/content-blocks/partials/structured-data';
import Products from 'components/content-blocks/products';
import MoreIndustries from 'components/globals/more-industries';
import Integrations from 'components/globals/payroll-integrations';
import Yoast from 'components/globals/yoast';
import { HeroFeatures } from 'components/hero/features';

import './styles/time-clock.scss';

export default function TimeClock() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "time-clock" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                copy
                extra_content
                signup {
                  unique_id
                  modal_title
                  legal_text
                  modal_submit_button_text
                  launch_modal_button_text
                  footer_signup_title
                }
                background_desktop_image {
                  source_url
                  alt_text
                }
              }

              faq {
                question
                answer
              }
              customer_feature_benefit {
                sub_title
                title
                copy
                customer {
                  business
                  name
                  photo_desktop {
                    source_url
                  }
                  photo_mobile {
                    source_url
                  }
                  since
                }
                stats {
                  value_1
                  descriptor_1
                  value_2
                  descriptor_2
                  value_3
                  descriptor_3
                }
              }
              customer_quote_1 {
                company_info
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
                quote
              }
              basic_title_subtitle_copy_image_2 {
                title
                copy
                copy_2
                demo_url
                button_text
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon_from_library
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              cards_1 {
                header {
                  title
                  sub_title
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                  is_active
                }
              }
              cards_2 {
                header {
                  title
                  sub_title
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                  is_active
                }
              }
              media_object {
                body
                sub_title
                title
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
                button_url
                button_text
                wysiwyg
              }
              customer_stories {
                card_groups {
                  card {
                    card_link
                    card_link_title
                    image {
                      alt_text
                      source_url
                    }
                    sub_title
                    title
                  }
                }
                card_section_title
                section {
                  copy
                  sub_title
                  title
                }
              }
              feature_manager_overview {
                copy
                title
                sub_title
                app_logos {
                  app_store {
                    source_url
                    alt_text
                  }
                  google_play {
                    source_url
                    alt_text
                  }
                }
                actions {
                  cards {
                    copy
                    phone_screen {
                      source_url
                      alt_text
                    }
                  }
                  mobile_card {
                    phone_screen {
                      source_url
                      alt_text
                    }
                  }
                  title
                }
                face_icons {
                  center {
                    source_url
                    alt_text
                  }
                  right {
                    source_url
                    alt_text
                  }
                  left {
                    source_url
                    alt_text
                  }
                }
                phone_case_background {
                  left_and_right {
                    source_url
                    alt_text
                  }
                  center {
                    source_url
                    alt_text
                  }
                }
              }
              features_list {
                sub_title
                title
                items {
                  icon
                  body
                  desktop_image {
                    alt_text
                    source_url
                  }
                  mobile_image {
                    source_url
                    alt_text
                  }
                  title
                }
              }
            }
          }
        }
      }
      wordpressAcfOptions {
        options {
          structured_data {
            product_time_clock
            faq {
              time_clock
            }
          }
          payroll_integrations {
            background_image_desktop {
              source_url
            }
            background_image_mobile {
              source_url
            }
            title
            sub_title
            copy
            logos {
              logo {
                source_url
                alt_text
              }
            }
          }
          industries {
            industries {
              url
              title
              icon {
                alt_text
                source_url
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const integrations = data.wordpressAcfOptions.options.payroll_integrations;
  const mediaObject = pageContext.media_object;
  const structuredData = data.wordpressAcfOptions.options.structured_data.product_time_clock;
  const faq = pageContext.faq;
  const faqStructuredData = data.wordpressAcfOptions.options.structured_data.faq.time_clock;
  const customerQuote = pageContext.customer_quote_1;
  const customerCard = pageContext.customer_feature_benefit;
  const featuresGrid = pageContext.features_grid;
  const featuresList = pageContext.features_list;
  const featureManagerOverview = pageContext.feature_manager_overview;
  const cards1 = pageContext.cards_1;
  const cards2 = pageContext.cards_2;
  const moreIndustries = data.wordpressAcfOptions.options.industries;
  const basicUs = pageContext.basic_title_subtitle_copy_image_2;
    
  return (
    <Layout
      faq={ faq }
      useStaticFaq={ true }
      faqStructuredData={ faqStructuredData }
      footerCtaTitle={ pageContext.hero.signup.footer_signup_title }
      modalTitle={ pageContext.hero.signup.modal_title }
      legalText={ hero.signup.legal_text }
    >
      <div className="features-time-clock-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
        <HeroFeatures
          { ...hero }
          uniqueId="timeClockFeature"
          section="featuresTimeClockHero"
        />
        <Highlight { ...cards1 } hasImage hasTitle titleAsHeader />
        <StructuredData data={ structuredData } />
        <Integrations { ...integrations } />
        <Manager { ...featureManagerOverview[0] } />
        <MediaObject { ...mediaObject[1] } className="features-payroll reverse" />
        <MediaObject { ...mediaObject[0] } />
        <MediaObject { ...mediaObject[2] } className="reverse more-padding" />
        <CustomerFeatureBenefit { ...customerCard[0] } />
        <FeaturesList { ...featuresList } />
        <FeaturesGrid { ...featuresGrid[0] } className="reverse" />
        <QuoteBox { ...customerQuote } />
        <BasicContainer { ...basicUs } uniqueId="time-clock-start-trial" hideMobile />
        <FeaturesGrid { ...featuresGrid[1] } />
        <CustomerStories
          { ...pageContext.customer_stories }
          hasNoIndustryTitles
        />
        <MoreIndustries { ...moreIndustries } />
        <Products
          { ...cards2 }
          modalTitle={ hero.modal_title }
          startingIndex={ 2 }
          hasTitle
          hasCopy
          hasHeadline
          activeIndex={ 2 }
        />
      </div>
    </Layout>
  );
}
